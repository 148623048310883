<script>
import baseUrl from "@/shared/baseUrl";
export default {
  name: "reset_password.vue",
  data() {
    return {
      password: "",
      alertCss: "alert-warning",
      cpassword: "",
      msg: "",
    };
  },
  methods: {
    resetpassword() {
      $("#loader").show();
      const user = {
        password: this.password,
        cpassword: this.cpassword,
      };
      if (user.password == "") {
        this.msg = "Please enter password";
        $("#loader").hide();
      } else if (user.cpassword == "") {
        this.msg = "Please enter confirm password";
        $("#loader").hide();
      } else if (user.password != user.cpassword) {
        this.msg = "Password and confirm password does not match";
        $("#loader").hide();
      } else {
        let token=this.$route.params.token;
        const payload ={
          token : token,
          password: this.password
        }
        baseUrl
          .post("/resetpassword", payload)
          .then((response) => {
            $("#loader").hide();
            console.log(response.data);
            if (response.data.status == "00") {
              this.alertCss='alert-success'
              console.log(response.data.data);
              const vm = this;
              this.msg = response.data.message;
              setTimeout(function () {
                vm.$router.push("/login");
              }, 5000); 
            } else {
              this.alertCss='alert-danger';
              console.log(response.data.data);
              this.msg = response.data.message;
            }
          })
          .catch((error) => {
            $("#loader").hide();
            this.msg = error.message;
          });
      }
    },
  },
}
</script>

<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo1" style="display: flex; justify-content: center">
                <img src="../../public/images/logo3.png" alt="logo" style="width: 30%" />
              </div>
              <h4>
                Reset Password
              </h4>
              <form class="pt-3" @submit.prevent="resetpassword">
                <div   :class="[alertCss]" class="alert  alert-dismissible fade show" role="alert" v-if="msg != ''">
                  <strong>{{ msg }}</strong>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-group">
                  <input type="password" name="password" class="form-control form-control-lg" 
                         placeholder="Password" v-model="password" style="color: #242323" />
                </div>
                <div class="form-group">
                  <input type="password" name="cpassword" class="form-control form-control-lg"  
                         placeholder="Confirm Password" v-model="cpassword" style="color: #242323" />
                </div>

                <div class="mt-3">
                  <button type="submit"
                          class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-white"
                          style="background-color: #1e1e2f; border: none">
                    RESET PASSWORD
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>

<style scoped>

</style>